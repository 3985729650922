import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import * as styles from "../Staff.module.scss";
import slugify from "slugify";
import ContactForm from "@components/ContactForm";

import bunny from "@images/index_page/logo1.svg";

const buttonOptions = {
  year: "numeric",
  month: "long",
  day: "numeric"
};


const SingleArticleButton = ({ header, title, created }) => {
  const slug = slugify(title, { lower: true });
console.log(created)
  return (
    
    <article className={styles.article_button}>
      <Link to={`../../articles/${slug}`} className={styles.single_article_card}>
      <h6>{title}</h6>
      <p>{new Date(created).toLocaleDateString("en-US", buttonOptions)}</p>
    </Link>
    </article>
  );
};

const NoArticleButton = () => {
  return (
    
    <article className={styles.no_article_button}>
      <img src={bunny} />
      <h6>Nothing to see here yet...</h6>
    </article>
  );
};

const SingleStaffComponent = ({ allArticles: { nodes: articles } , staff }) => {
  const {
    name,
    picture,
    position,
    bio,
    since,
    availability,
    skills
  } = staff;
  return (
    <div className={`${styles.single_staff_container} container`}>
      <section className={styles.single_staff_info}>
        <div className={styles.single_staff_top}>
          <img src={picture[0].image} />
          <div className={styles.name_and_position}>
            <h2>{name}</h2>
            <h3>{position}</h3>
          </div>
        </div>

        <p className={styles.single_availability}>
          <span></span>
          {availability && "Available"}
        </p>

        <article>
          <p className={styles.bio}>{bio}</p>
          {/* <p className={styles.since}>
            At Magyk since{" "}
            {new Date(since).toLocaleDateString("en-US", options)}
          </p> */}
        </article>

        <h4>Skills and Abilities</h4>
        <ul>
          {skills.map(skill => (
            <li clasName={styles.bullet}>
              {skill.title} - {skill.level[0].title}
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.single_staff_articles}>
        <h5>Articles by {name.split(" ").slice(0, 1)}</h5>
        <section className={styles.article_buttons}>
          {articles && articles.map(article => article.published ? <SingleArticleButton {...article} key={article.id} /> : <></>)}
          {articles?.length===0 &&  <NoArticleButton />}
          {articles?.length > 3 && <button>See more</button>} {/* TODO add functionality to button*/}
        </section>
      </section>
    </div>
  );
};

export default SingleStaffComponent;
