import * as React from "react";
import SingleStaffComponent from "@components/Staff/SingleStaff";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import SEO from "@components/seo";


const SingleStaffPage = ({ data }) => {
console.log(data)

  return (
    <>
      <Layout>
        {/* <SEO title={`Article: ${article.title}`} /> */}
        <SingleStaffComponent {...data} />
      </Layout>
    </>
  );
};

export const query = graphql`
query SingleStaffPageQuery($name: String) {
  staff(name: {eq: $name}) {
    availability
    bio
    id
    name
    picture {
      image
      name
    }
    position
    skills {
      level {
        title
      }
      title
    }
  }
  allArticles(filter: {staff: {elemMatch: {name: {eq: $name}}}}) {
    nodes {
      header
      id
      title
      created
      published
    }
  }
}

`;

export default SingleStaffPage;
