// extracted by mini-css-extract-plugin
export const header = "Staff-module--header--GSePb";
export const staff_cards_section = "Staff-module--staff_cards_section--14KkB";
export const staff_card = "Staff-module--staff_card--2aDB4";
export const staff_card_top = "Staff-module--staff_card_top--2t9sZ";
export const name_and_position = "Staff-module--name_and_position--24WRc";
export const availability_light = "Staff-module--availability_light--219Lj";
export const wiggleLeft = "Staff-module--wiggle-left--1--Ey";
export const wiggleRight = "Staff-module--wiggle-right--nQ11A";
export const single_staff_container = "Staff-module--single_staff_container--2vGR-";
export const single_staff_top = "Staff-module--single_staff_top--DeXwb";
export const single_availability = "Staff-module--single_availability--1EQoF";
export const bio = "Staff-module--bio--1PiIQ";
export const since = "Staff-module--since--uFgIZ";
export const article_buttons = "Staff-module--article_buttons--29xjF";
export const article_button = "Staff-module--article_button--XytRI";
export const no_article_button = "Staff-module--no_article_button--1pcDV";
export const see_more_button = "Staff-module--see_more_button--iXhLn";
export const single_staff_info = "Staff-module--single_staff_info--2aeLA";
export const single_staff_articles = "Staff-module--single_staff_articles--3GXr5";
export const bouncy = "Staff-module--bouncy--2Tjkq";
export const pop = "Staff-module--pop--3tE5d";